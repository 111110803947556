<template>
    <v-sheet>
        <render-content id="contact"> </render-content>
        <contact-form> </contact-form>
    </v-sheet>
</template>
<script>
import RenderContent from '@c/ui/RenderContent.vue'
import ContactForm from '@c/ui/ContactForm.vue'
    export default {
        name: "Contact",
        components:{ 
            RenderContent,
            ContactForm
        }
    }
</script>